<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 7 <strong>saved</strong>successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 7: Homeowner Info
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #1 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #2 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #3 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.HomeNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Projected Closing Date"
                                    type="date"
                                    color="black"
                                    v-model="selected_user.BuildEnd"
                                />
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: Plumbing Fixtures
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-header>STANDARD PLUMBING FIXTURES USED IN MODEL WILL BE INSTALLED IN BRUSHED NICKEL</v-header>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                            <v-header>HOMEOWNER TO REMAIN W/STANDARD MODEL HOME DELTA PACKAGES:</v-header>
                            </v-col>
                          <v-col cols="12" md=""><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_1"></v-select></v-col>
                          </v-row>
                    </v-container>
                </v-form>
                </base-material-card>

                  <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: Master Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="5">
                            <v-header>MASTER BATH (ALL STD. FIXTURES IN THE ROOM):</v-header>
                            </v-col>
                              <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_2"></v-select></v-col>
                        </v-row>
                      <v-divider></v-divider>
                        <v-row>
                           <v-col cols="12">
                            <v-header><strong>Tub</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Tub:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['5ft','6ft','Optional Soaking','Undecided']" v-model="section.column_3"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_4"></v-select>
                            </v-col>
                           <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4" md="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Brushed Nickel','Rub Bronze','Undecided']" v-model="section.column_5"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                             <v-col cols="12">
                               <v-header><strong>Shower</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Shower:</v-subheader></v-col>
                            <v-col cols="12" md="7">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. 48” x 36” Fiberglass','Optional 60” x 36” w/2 Seats','Custom/per drawing','Undecided']" v-model="section.column_8"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_9"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4" md="3">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Brushed Nickel','Rub Bronze','Undecided']" v-model="section.column_10"></v-select>
                            </v-col>
                            <v-col cols="12" md="1" ><v-subheader>All Tile:</v-subheader></v-col>
                            <v-col cols="4" md="3">
                              <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_14"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Glass & Tile:</v-subheader></v-col>
                            <v-col cols="4" md="3">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_15"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                             <v-col cols="12">
                            <v-header><strong>Stool</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Stool:</v-subheader></v-col>
                            <v-col cols="4" md="5">
                                <v-select :readonly="permissions === 'read:homeowner'" 
                                  :items="[`Elongated ($${this.price_section_seven.column_35})`, `Elongated/Comfort Height ($${this.price_section_seven.column_36})`,'Undecided']" 
                                  v-model="section.column_16">
                                </v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_17"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Seat STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_18"></v-select>
                            </v-col>
                          </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                            <v-col cols="12">
                            <v-header><strong>Sink</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Sink:</v-subheader></v-col>
                            <v-col cols="4" md="7">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Laminate','Option: Solid Surface','Option: Granite/Quartz','Undecided']" v-model="section.column_23"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color Confirmed:</v-subheader></v-col>
                            <v-col cols="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_24"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['15999 SS DST','Optional 15999 RB DST','Undecided']" v-model="section.column_25"></v-select>
                            </v-col>
                        </v-row>
                 </v-container>
                </v-form>
                </base-material-card>
                  <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: Main Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="5">
                            <v-header>MAIN BATH (ALL STD. FIXTURES IN THE ROOM):</v-header>
                            </v-col>
                              <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_26"></v-select></v-col>
                        </v-row>
                      <v-divider></v-divider>
                        <v-row>
                           <v-col cols="12">
                            <v-header><strong>Tub-Shower</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Tub-Shower</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['5ft','6ft','Optional Soaking','Undecided']" v-model="section.column_27"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_28"></v-select>
                            </v-col>
                           <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4" md="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Brushed Nickel','Rub Bronze','Undecided']" v-model="section.column_29"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                             <v-col cols="12">
                            <v-header><strong>Stool</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Stool:</v-subheader></v-col>
                            <v-col cols="4" md="5">
                                <v-select :readonly="permissions === 'read:homeowner'" 
                                  :items="[`Elongated ($${this.price_section_seven.column_35})`,`Elongated/Comfort Height ($${this.price_section_seven.column_36})`,'Undecided']" 
                                  v-model="section.column_35">
                                </v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_36"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Seat STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_37"></v-select>
                            </v-col>
                          </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                            <v-col cols="12">
                            <v-header><strong>Sink</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Sink:</v-subheader></v-col>
                            <v-col cols="4" md="7">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Laminate','Option: Solid Surface','Option: Granite/Quartz','Undecided']" v-model="section.column_38"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color Confirmed:</v-subheader></v-col>
                            <v-col cols="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_39"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['15999 SS DST','Optional 15999 RB DST','Undecided']" v-model="section.column_40"></v-select>
                            </v-col>
                        </v-row>
                 </v-container>
                </v-form>
                </base-material-card>
                  <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: 1/2 Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">

                        <v-row>
                            <v-col cols="4">
                            <v-header>1/2 Bath (ALL STD. FIXTURES IN THE ROOM)</v-header>
                            </v-col>
                              <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_41"></v-select></v-col>
                        </v-row>
                      <v-divider></v-divider>
                        <v-row>
                             <v-col cols="12">
                            <v-header><strong>Stool</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Stool:</v-subheader></v-col>
                            <v-col cols="4" md="5">
                                <v-select :readonly="permissions === 'read:homeowner'" 
                                  :items="[`Elongated ($${this.price_section_seven.column_35})`, `Elongated/Comfort Height ($${this.price_section_seven.column_36})`,'Undecided']" 
                                  v-model="section.column_42">
                                </v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_43"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Seat STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_44"></v-select>
                            </v-col>
                          </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                            <v-col cols="12">
                            <v-header><strong>Sink</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Sink:</v-subheader></v-col>
                            <v-col cols="4" md="7">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Laminate','Option: Solid Surface','Option: Granite/Quartz','Undecided']" v-model="section.column_45"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color Confirmed:</v-subheader></v-col>
                            <v-col cols="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_46"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['15999 SS DST','Optional 15999 RB DST','Undecided']" v-model="section.column_47"></v-select>
                            </v-col>
                        </v-row>
                  </v-container>
                  </v-form>
                  </base-material-card>
                  <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: Basement Bath (Full) (3/4) (1/2)
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="6">
                            <v-header>BASEMENT BATH (Full) (3/4) (1/2) (ALL STD. FIXTURES IN THE ROOM)</v-header>
                            </v-col>
                              <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_48"></v-select></v-col>
                        </v-row>
                      <v-divider></v-divider>
                        <v-row>
                             <v-col cols="12">
                            <v-header>Shower</v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Shower:</v-subheader></v-col>
                            <v-col cols="12" md="3">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. 48” x 36”','Undecided']" v-model="section.column_49"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_50"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4" md="3">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Brushed Nickel','Rub Bronze','Undecided']" v-model="section.column_51"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                          <v-col cols="12">
                            <v-header><strong>Stool</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Stool:</v-subheader></v-col>
                            <v-col cols="4" md="5">
                              <v-select :readonly="permissions === 'read:homeowner'" 
                                :items="[`Elongated ($${this.price_section_seven.column_35})`, `Elongated/Comfort Height ($${this.price_section_seven.column_36})`,'Undecided']" 
                                v-model="section.column_52">
                              </v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Color STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_53"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Seat STD. White:</v-subheader></v-col>
                            <v-col cols="4" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_54"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>
                        <v-row>
                            <v-col cols="12">
                            <v-header><strong>Sink</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Sink:</v-subheader></v-col>
                            <v-col cols="4" md="7">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Laminate','Option: Solid Surface','Option: Granite/Quartz','Undecided']" v-model="section.column_55"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Color Confirmed:</v-subheader></v-col>
                            <v-col cols="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_56"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Faucet:</v-subheader></v-col>
                            <v-col cols="4">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['15999 SS DST','Optional 15999 RB DST','Undecided']" v-model="section.column_57"></v-select>
                            </v-col>
                        </v-row>
                  </v-container>
                  </v-form>
                  </base-material-card>
                  <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 7: Kitchen Sink
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="3">
                            <v-header>STD. KITCHEN TOP MOUNT SINK</v-header>
                            </v-col>
                              <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Laminate','Stainless Color','Undecided']" v-model="section.column_56"></v-select></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="3" ><v-subheader>Optional Under mount Sink: Laminate:</v-subheader></v-col>
                            <v-col cols="12" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_57"></v-select>
                            </v-col>
                          <v-col cols="12" md="2" ><v-subheader>Optional Sink:</v-subheader></v-col>
                            <v-col cols="12" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_58"></v-select>
                            </v-col>
                          <v-col cols="12" md="3">
                                <v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_59"></v-text-field>
                            </v-col>
                          <v-col cols="12" md="3" ><v-subheader>Optional Exquisite Stone provided Sink:</v-subheader></v-col>
                            <v-col cols="6" md="2">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_60"></v-select>
                            </v-col>
                          <v-col cols="12" md="1" ><v-subheader>Kitchen Faucet:</v-subheader></v-col>
                            <v-col cols="12" md="6">
                                <v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Kitchen Faucet: Delta 4153 SS DST (Brushed Nickel)','Optional Kitchen Faucet: Delta 4153 RB DST (Oil Rub Bronze)','Undecided']" v-model="section.column_61"></v-select>
                            </v-col>
                        </v-row>
                      <v-divider></v-divider>
                      <v-spacer/>

                        <v-row>
                          <v-col col="12" md="12">
                                <v-header>Notes:<v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_61"/></v-header>
                            </v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template> -->
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select :readonly="permissions === 'read:homeowner'"
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes','No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                           </v-dialog>
                        </v-row>

                    </v-container>
                </v-form>
                </base-material-card>

              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_62"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_63"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_64"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_65"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 7: Homebuyer's To Supply Own Plumbing Fixtures
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-header>If you choose to supply your own plumbing fixtures, the following policies will be in effect immediately</v-header>
                            </v-col>
                            <v-col cols="12">

                                <p>1) NO WARRANTY - Any product not supplied by our vendor, that is defective will require the home - buyer to contact the manufacture of the product or the retail store where it was purchased.</p>
                                <p>2) Our vendor will not be responsible for any defects unless it can be proven that the defect was caused by our vendor while installing the product.</p>
                                <p>3) Homebuyer is required to inspect the product for any scratches, dents or other defects before providing it to our vendor.</p>
                                <p>4) The Homebuyer may receive a replacement for a defective product for free but any labor to replace it is not free and our vendor will charge to do the work.</p>
                                <p>If a part the Homebuyer is supplying is missing or incorrect at the time of installation, the Homebuyer is subject to:</p>
                                <p>5) ${{this.price_section_seven.column_37}} trip charge per trip for our vendor to come back to the job site or go to wholesaler to get the correct or missing product.</p>
                                <p>6) ${{this.price_section_seven.column_38}}/hour labor rate to pay for the extra time required to get the correct product and to complete the job.</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                </base-material-card>
                            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_66"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_67"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_68"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_69"/>
                                </v-col>
                           <v-col cols="12" md="3">
                                    <v-text-field :readonly="permissions === 'read:homeowner'" label="Seller/Builder"
                                    v-model="section.column_70"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_71"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

                <v-spacer/>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
    name:'Section7',
    data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      section_id: "7",
      staff_email: 'steve@skelleymarketing.com',
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      price_section_seven: {
        column_35: '',
        column_36: '',
        column_37: '',
        column_38: ''
      },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
        column_32:'',
        column_33:'',
        column_34:'',
        column_35:'',
        column_36:'',
        column_37:'',
        column_38:'',
        column_39:'',
        column_40:'',
        column_41:'',
        column_42:'',
        column_43:'',
        column_44:'',
        column_45:'',
        column_46:'',
        column_47:'',
        column_48:'',
        column_49:'',
        column_50:'',
        column_51:'',
        column_52:'',
        column_53:'',
        column_54:'',
        column_55:'',
        column_56:'',
        column_57:'',
        column_58:'',
        column_59:'',
        column_60:'',
        column_61:'',
        column_62:'',
        column_63:'',
        column_64:'',
        column_65:'',
        column_66:'',
        column_67:'',
        column_68:'',
        column_69:'',
        column_70:'',
        column_71:'',
        column_72:'',
        column_73:'',
        column_74:'',
        column_75:'',
        column_76:'',
        column_77:'',
        column_78:'',
        column_79:'',
        column_80:'',
        column_81:'',
        column_82:'',
        column_83:'',
        column_84:'',
        column_85:'',
        column_86:'',
        column_87:'',
        column_88:'',
        column_89:'',
        column_90:'',
        column_91:'',
        column_92:'',
        column_93:'',
        column_94:'',
        column_95:'',
        column_96:'',
        column_97:''
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
  created () {
    this.getSection()
    this.getHomePrice()
    this.getVendorEmail()
    },
    methods:{

      getSection(){
          axios
          .get(process.env.VUE_APP_GET_SECTION_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response =>{

              if (response.data)
              {
                  var result = response.data
                  this.section = JSON.parse(result['JsonDetails'])
              }
          })
          .catch(error =>{
          console.log(error)
          })
      },
      getVendorEmail (){
        axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,
        {
          // params: {
          // }
        }
        )
        .then(response =>{
          this.vendor_email = response.data
        })
        .catch(error =>{
          console.log(error)
        })
      },
      getHomePrice(){
          axios
          .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
          {
          })
          .then(response =>{

              if (response.data)
              {
                  var result = response.data
                  this.price_section_seven = JSON.parse(result['SectionSeven']) || {}
              }
          })
          .catch(error =>{
          console.log(error)
          })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(response =>{
              console.log(response.data)
              this.alert = true
          })
          .catch(error =>{
          console.log(error)
          })
      },
      sectionComplete(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
          .then(response =>{
              console.log(response.data)
              this.alert = true
              this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
          })
          .catch(error =>{
          console.log(error)
          })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
      },
      close(){
        this.dialog = false
        this.$nextTick(() =>{
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
        })
      },
      sendEmail(to,from,template,content,section){

      var email_body ={
        "to": to,
        "from": from,
        "template_name": template,
        "template_data": {
          "content": content,
          "section": section
        }
      }

      axios
      .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
      .then(response =>{
        console.log(response.data)
      })
      .catch(error =>{
        console.log(error)
      })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>